
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import DataTableHandler from '@/components/DataTable/DataTable';
import EventBus from '@/event-bus';
import LoadingTracker from '@/helpers/LoadingTracker';
import Message from '@/components/mixins/Message.vue';
import { isEmptyArray } from '@/helpers/arrayHelpers';
import DataTable from '@/components/DataTable/DataTable.vue';

// Lazy loading imports
const ListAddFields = () => import(
  /* webpackChunkName: "list-add-fields" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/ListAddFields.vue'
);
const ListBulkActions = () => import(
  /* webpackChunkName: "list-bulk-actions" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/ListBulkActions.vue'
);

@Component({
  name: 'DossierTypeList',
  methods: {
    isEmptyArray,
  },
  components: {
    DataTable,
    BaseCard,
    ListAddFields,
    ListBulkActions,
  },
})
export default class DossierTypeList extends Vue {
  @Prop()
  private readonly navigationSlug?: string;

  @Prop()
  private readonly dossierTypeSlug?: string;

  @Prop()
  private readonly parentComponent?: string;

  @Prop({ default: null })
  private readonly parentFetchData!: null | (() => void);

  private loadingTracker = new LoadingTracker();

  private loading = false;

  private dataTable = new DataTableHandler('dossierTypeList');

  protected title = '';

  private hasAddFields = false;

  private hasExport = false;

  private openDossierWhenCreated = false;

  async created(): Promise<void> {
    const forceRerender = () => this.fetchData('no-cache');

    EventBus.$on('forceRerender', forceRerender);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('forceRerender', forceRerender);
    });
  }

  private getNavigationSlug(): string {
    if (this.navigationSlug === undefined) {
      return this.$route.params.navigationSlug;
    }
    return this.navigationSlug;
  }

  private getDossierTypeSlug(): string {
    if (this.dossierTypeSlug === undefined) {
      return this.$route.params.dossierTypeSlug;
    }
    return this.dossierTypeSlug;
  }

  private fetchData(fetchPolicy: FetchPolicy = 'no-cache'): void {
    if (this.parentFetchData !== null) {
      this.parentFetchData();
    } else {
      this.loading = true;

      const variables = {
        ...this.dataTable.getQueryVariables(),
        navigationSlug: this.getNavigationSlug(),
        dossierTypeSlug: this.getDossierTypeSlug(),
      };

      import('@/graphql/queries/dossier-type-list-by-navigation-slug')
        .then(({ default: query }) => this.$apollo.query({
          fetchPolicy,
          query,
          variables,
        }))
        .then((response) => {
          this.dataTable.handleData(response.data);
          this.hasAddFields = response.data.list.dataGridInfo.metadata['list-settings']?.quick_add ?? false;
          this.hasExport = response.data.list.dataGridInfo.metadata['list-settings']?.export ?? false;
          if (this.$route.name !== 'Dashboard') {
            this.openDossierWhenCreated = response.data.list.dataGridInfo.metadata['list-settings']?.form_zoom_after_add ?? false;
          }
          this.title = `${response.data.listTitle} (${this.dataTable.total})`;
        })
        .catch((error) => {
          Message.error(this.$t('generic.error.occurred'));
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  private onSaveNewItem(dossierId: string) {
    if (!this.openDossierWhenCreated) {
      return;
    }

    this.$router.push({
      name: 'dossier-detail',
      params: {
        navigationGroup: this.$route.params.navigationGroup,
        dossierTypeSlug: this.getDossierTypeSlug(),
        navigationSlug: this.getNavigationSlug(),
        dossierId,
      },
    });
  }

  private exportFile(fetchPolicy: FetchPolicy = 'no-cache'): void {
    this.loading = true;

    const variables = {
      sorters: this.dataTable.getQueryVariables().sorters,
      filters: this.dataTable.getQueryVariables().filters,
      navigationSlug: this.getNavigationSlug(),
      dossierTypeSlug: this.getDossierTypeSlug(),
    };
    import('@/graphql/queries/export-dossier-type-list-by-navigation-slug')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables,
        fetchPolicy,
      }))
      .then((response) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.export.data}`;
        const downloadLink = document.createElement('a');
        const fileName = response.data.export.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
